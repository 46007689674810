import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogPost = ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const { date } = pageContext;
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
        type="article"
        node={post}
      />
      <article className="mx-auto prose lg:prose-xl dark:prose-invert prose-a:text-indigo-600 dark:prose-a:text-indigo-400">
        <header>
          <h1>{post.frontmatter.title}</h1>
          <time dateTime={date}>{post.frontmatter.date}</time>
        </header>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        {post.frontmatter.related != null && (
          <footer>
            <h3>Related Links</h3>
            <ul>
              {post.frontmatter.related.map((item, index) => (
                <li key={index}>
                  <a href={item.link}>{item.title}</a>
                </li>
              ))}
            </ul>
          </footer>
        )}
      </article>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
        date
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image
        related {
          title
          link
        }
      }
    }
  }
`;

export default BlogPost;
